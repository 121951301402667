<template>
  <div class="video-link-asset d-flex flex-column p-4" :class="{ as_list: renderAsList }">
    <div class="header d-flex flex-row align-items-center">
      <b-icon class="header-icon" icon="camera-video"></b-icon>
      <span>VIDEO</span>
    </div>
    <div v-if="videoLinkAsset.title" class="title mt-2">{{ videoLinkAsset.title }}</div>
    <div v-if="videoLinkAsset.description" class="description">
      {{ videoLinkAsset.description }}
    </div>

    <div class="preview-img position-relative mt-1" @click="openVideo" v-if="previewUrl">
      <b-img alt="video preview" :src="previewUrl"></b-img>
      <b-icon-play-fill />
    </div>
    <div v-if="videoLinkAsset.identifier" class="d-flex identifier-wrapper">
      <a :href="shortLink" target="_blank">uevi.co/{{ videoLinkAsset.identifier }}</a>
    </div>
  </div>
</template>
<script>
import AssetSourceMixin from '@app/mixins/asset_source'
import BrandedAssetMixin from '@app/mixins/branded_asset.ts'
import { getPreviewUrl } from '@lib/video_urls'
import { generateShareLink } from '@lib/share_link'

export default {
  name: 'VideoLinkAsset',
  mixins: [AssetSourceMixin, BrandedAssetMixin],
  props: {
    videoLinkAsset: { type: Object, required: true },
    renderAsList: { type: Boolean, required: false, default: false },
    account: { type: Object, required: true },
  },
  methods: {
    openVideo() {
      window.open(this.videoLinkAsset.url, '_blank')
    },
  },
  computed: {
    statColorOption() {
      return 'primary'
    },
    displayLogo() {
      return this.account.svg_logo_mark
    },
    contentAsset() {
      return this.videoLinkAsset
    },
    shortLink() {
      return generateShareLink(this.videoLinkAsset.identifier)
    },
    previewUrl() {
      return getPreviewUrl(this.videoLinkAsset?.url)
    },
  },
}
</script>
<style lang="sass" scoped>
button.option-card
  border-color: v-bind("chartColor")
  color: v-bind("chartColor")
  background-color: v-bind("footerColor")

.header-icon
  font-size: 1.2em

.preview
  border: 1px solid rgba(108, 116, 125, 1)

  ::v-deep svg
    width: 3.5em
    height: 3.5em
    opacity: 0.9

.video-link-asset
  word-break: break-word
  // min-width breaks the asset list view, do we really need it?
  // min-width: 540px
  border: 1px solid hsl(200, 24%, 92%)
  border-radius: 0.8em

  .header
    gap: 0.5em
    font-weight: $font-weight-bold
    color: $uePurple
    span
      font-size: 0.8em

.title
  color: rgba(33, 37, 41, 1)
  font-weight: 700
  font-size: 1.3em

.description
  color: rgba(108, 116, 125, 1)
  font-weight: 400
  font-size: 0.875em

.as_list
  &:hover
    border: 1px solid $uePurple
  .title
    font-size: 1em
  .description
    font-size: 0.8em

.identifier-wrapper
  border-top: 1px solid rgba(206, 212, 218, 1)
  margin-top: 1em
  a
    padding-top: 1em
    font-weight: 700
    font-size: 0.875em
    margin-left: auto
    margin-right: 0
    text-decoration: none
    color: rgba(0, 123, 255, 1)

.preview-img
  cursor: pointer
  justify-content: center
  align-items: center

.preview-img img
  width: 100%
  height: auto

.preview-img .b-icon
  color: white
  width: 120px
  height: 120px
  top: 50%
  left: 50%
  position: absolute
  transform: translate(-50%, -50%)
</style>
