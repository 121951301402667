<template lang="pug">
  .asset_presenter(v-if="content_asset && content_asset.type == 'RenderableTestimonial'")
    TestimonialSocial191.crimp(
      :renderableTestimonial='content_asset'
      :shape="shape"
      :show_all_pages="show_all_pages"
      :page="page"
      :account='account'
      v-if='style == "styled"'
    )
    TestimonialBranded.crimp(
      :renderableTestimonial='content_asset'
      :backgroundColorOption='backgroundColorOption'
      :account='account'
      v-if='style == "branded"'
    )
    TestimonialBasic(
      :renderable-testimonial='content_asset'
      :shape="shape"
      :showAllPages="show_all_pages"
      :page="page"
      :account="account"
      v-if='style == "basic"')
    TestimonialCustom.crimp(
      :renderable-testimonial='content_asset'
      :shape="shape"
      :show_all_pages="show_all_pages"
      :page="page"
      :account="account"
      :theme="theme"
      v-if='style.match(/^custom/)'
    )
  .asset_presenter(v-else-if="content_asset && content_asset.type == 'ChartAsset'")
    BarChartBasic.crimp(
      :content-asset='content_asset'
      :chartColorOption='stat_color_option'
      v-if='design == "basic"'
    )
    BarChartBranded.crimp(
      :content-asset='content_asset'
      :chartColorOption='stat_color_option'
      :backgroundColorOption='background_color_option'
      v-if='design == "branded"'
    )
  .asset_presenter(v-else-if="content_asset && content_asset.type == 'StatAsset'")
    StatBasic.crimp(:content-asset='content_asset' :statColorOption='stat_color_option' v-if='design=="basic"')
    StatBranded.crimp(:content-asset='content_asset' :statColorOption='stat_color_option' :backgroundColorOption='background_color_option' v-if='design == "branded"')
  .asset_presenter(v-else-if="content_asset && content_asset.type == 'SurveySummary'")
    SurveySummary(:content-asset='content_asset' :account="content_asset.account")
  .asset_presenter(v-else-if="content_asset && content_asset.type == 'MatrixxChart'")
    MatrixxChartBranded.crimp(:matrixxChart='content_asset' :account='content_asset.account' :statColorOption='stat_color_option' :backgroundColorOption='background_color_option' v-if="style == 'branded'")
    MatrixxChartBasic.crimp(:matrixxChart='content_asset' :statColorOption='stat_color_option' :account='content_asset.account' v-else)
  .asset_presenter(v-else-if='content_asset && content_asset.type == "CustomerSpotlight"')
    CustomerSpotlight(:customer_spotlight="content_asset" :account="account")
  .asset_presenter(v-else-if='content_asset && content_asset.type == "VideoLinkAsset"')
    VideoLinkAsset(:video-link-asset="content_asset" :account="account")
  .asset_presenter(v-else-if='content_asset && content_asset.type == "FileAsset"')
    FileAsset(:file-asset="content_asset" :account="account")
  .asset_presenter(v-else-if='content_asset && content_asset.type == "SurveySpotlightAsset"')
    SurveySpotlight(:content_asset='content_asset' :horizontal='true')

</template>
<script lang="ts">
import StatBasic from '@components/StatBasic.vue'
import StatBranded from '@components/StatBranded.vue'
import TestimonialSocial191 from '../../components/TestimonialSocial191.vue'
import TestimonialBasic from '../../components/TestimonialBasic.vue'
import TestimonialBranded from '../../components/TestimonialBranded.vue'
import TestimonialCustom from '../../components/TestimonialCustom.vue'
import BarChartBasic from '@components/BarChartBasic'
import BarChartBranded from '@components/BarChartBranded'
import CustomerSpotlight from '../../components/CustomerSpotlight'
import SurveySpotlight from '../../components/SurveySpotlight'
import MatrixxChartBasic from '../../components/MatrixxChartBasic'
import MatrixxChartBranded from '../../components/MatrixxChartBranded'
import SurveySummary from '@components/SurveySummary'

export default {
  components: {
    BarChartBranded,
    StatBranded,
    StatBasic,
    TestimonialBasic,
    TestimonialSocial191,
    TestimonialBranded,
    TestimonialCustom,
    CustomerSpotlight,
    SurveySpotlight,
    MatrixxChartBasic,
    BarChartBasic,
    MatrixxChartBranded,
    SurveySummary,
  },
  props: [
    'content_asset',
    'shape',
    'show_all_pages',
    'page',
    'design',
    'account',
    'theme',

    // the following props are used by BrandedAssets
    'stat_color_option',
    'background_color_option',
  ],
  computed: {
    // style is only used for testmonials.
    // it looks at
    style() {
      if (this.design.includes('branded_')) {
        return 'branded'
      }

      return this.design
    },
    backgroundColorOption() {
      switch (this.design) {
        case 'branded_primary':
          return 'primary'
        case 'branded_secondary':
          return 'secondary'
        case 'branded_primary_and_secondary':
          return 'both'
        default:
          return undefined
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.crimp
  width: 624px !important
.customer_spotlight_multi_page_presenter
  display: inline-block
  width: 99999px
</style>
