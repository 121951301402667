// API calls for app API namespace - aspirationally
//
// Actually these functions were ported from the legacy mixins module so
// have a couple quirks:
//   - In many cases `this` is assumed to be bound to a Vue component
//   - There is some admin stuff lumped in here
// TODO: Get rid of the admin stuff, all the `this`, all the Vuex stuff.
//       Each function should just return the response data

import axiosBase from 'axios'

// @TODO These Pinia imports are supporting the global page loading behavior
// that happens when the app pages load. The unfortunate side effect of this
// is that it means this entire module is unusable outside of a Pinia context.
// This coupling isn't ideal because we want these exported methods to be pure
// functions that DON'T cause side-effeccts. The tentative plan is to refactor
// this such that the Pinia dependency only happens where it's relevant. There
// is a ticket in Linear for this.
import { useLoadingStore } from '@app/stores/LoadingStore'

export { default as fetchSummary } from '@lib/fetchSummary'

const surveyShowUrl = (survey_id) => `/app/api/surveys/${survey_id}.json`

const axios = axiosBase.create()

// Enter loading state when any request is initiated
axios.interceptors.request.use((config) => {
  useLoadingStore().trackRequest(config)
  return config
})

function handleResponseComplete(config) {
  useLoadingStore().untrackRequest(config.config)
  return config
}
// Exit loading state when any response is received
// `use` takes two arguments: a function to call on completed response and one to
// call on error. That's why the argument is repeated twice, we want to do the same thing
// in both cases which is to turn off the loading indicator.
axios.interceptors.response.use(handleResponseComplete, handleResponseComplete)

export function getSurvey(survey_id) {
  return axios.get(surveyShowUrl(survey_id))
}

export function saveQuestion() {
  return axios.patch(`/app/api/questions/${this.question.id}.json`, { question: this.question })
}

export function fetchApp() {
  return axios.get('/app/api/app.json')
}

export function createSurvey() {
  return axios.post('/app/api/surveys.json', { survey: this.survey })
}

export function updateSurvey(survey) {
  axios.patch(`/app/api/surveys/${survey.id}.json`, {
    survey: survey,
  })
}

export function getSurveyQuestions(survey_id) {
  return axios.get(`/app/api/surveys/${survey_id}/questions.json`)
}

export function getSurveys(params) {
  return axios.get('/app/api/surveys.json', { params: params })
}

export function getSurveysWithResponses(params) {
  const paramsWithResponses = { with_responses: true, ...params }
  return getSurveys(paramsWithResponses)
}

export function getSurveyStats(surveyId) {
  return axios.get(`/app/api/surveys/${surveyId}/stats.json`)
}

export function saveContentAsset(contentAsset) {
  return axios.patch(`/app/api/content_assets/${contentAsset.id}.json`, {
    content_asset: contentAsset,
  })
}

export function createContentAsset(contentAsset) {
  return axios.post('/app/api/content_assets.json', { content_asset: contentAsset })
}

export function publishAsset(contentAsset) {
  return axios.patch(`/app/api/content_assets/${contentAsset.id}/publish.json`)
}

export function getTestimonialThemes(account_id) {
  return axios.get(`/api/accounts/${account_id}/testimonial_themes.json`)
}

export function getTestimonialThemesMap(account_id) {
  return getTestimonialThemes(account_id).then((r) => {
    let fetched_themes = {}
    r.data.testimonial_themes.map((t) => {
      fetched_themes[t.id] = t
    })
    return fetched_themes
  })
}

export function getTestimonialTheme(id) {
  return axios.get(`/api/testimonial_themes/${id}.json`)
}

export function postTestimonialTheme(theme) {
  return axios.post('/app/api/testimonial_themes.json', theme)
}

export function patchTestimonialTheme(id, theme) {
  return axios.patch(`/app/api/testimonial_themes/${id}.json`, theme)
}

export function deleteTestimonialTheme(id) {
  return axios.delete(`/app/api/testimonial_themes/${id}.json`)
}

export function getContentAsset(content_asset_id) {
  return axios.get(`/app/api/content_assets/${content_asset_id}.json`)
}

export function saveAssetList(id, assetList) {
  return axios.patch(`/app/api/asset_lists/${id}.json`, {
    asset_list: assetList,
  })
}

export function getAssetList(asset_list_id) {
  return axios.get(`/app/api/asset_lists/${asset_list_id}.json`)
}

export function createTestimonial(renderable_testimonial, params = {}) {
  // This #create action wants a list of classifier_value_ids but usually we'll have a
  // list of classifier_values, so we'll munge the data a bit.
  const classifier_value_ids = renderable_testimonial.classifier_values?.map((c) => c.id)
  const survey_response_id = renderable_testimonial?.survey_response?.id

  return axios.post('/app/api/renderable_testimonials.json', {
    renderable_testimonial: {
      classifier_value_ids,
      survey_response_id,
      ...renderable_testimonial,
    },
    // Set aditional params that are not part of the renderable testimonial object
    ...params,
  })
}

export function getCustomerSpotlight(customer_spotlight_id) {
  return axios.get(`/api/customer_spotlights/${customer_spotlight_id}.json`)
}

export function createCustomerSpotlight(customer_spotlight) {
  return axios.post('/app/api/customer_spotlights.json', {
    customer_spotlight: customer_spotlight,
  })
}

export function updateCustomerSpotlight(customer_spotlight) {
  return axios.patch(`/app/api/customer_spotlights/${customer_spotlight.id}.json`, {
    customer_spotlight: customer_spotlight,
  })
}

export function destroyCustomerSpotlight(customer_spotlight_id) {
  return axios.delete(`/app/api/customer_spotlights/${customer_spotlight_id}.json`)
}

export function renderAsset(asset_id, options) {
  return axios.post(`/api/renderable_testimonials/${asset_id}/download.json`, options)
}

export function renderCustomerSpotlight(id, options) {
  return axios.post(`/api/customer_spotlights/${id}/download.json`, options)
}

export function downloadMatrixxChart(id, options) {
  return axios.post(`/api/matrixx_charts/${id}/download.json`, options)
}

export function downloadContentAsset(id, options) {
  return axios.post(`/api/content_assets/${id}/download.json`, options)
}

export function getRenderableTestimonial(id_or_hash) {
  return axios.get(`/api/renderable_testimonials/${id_or_hash}.json`)
}

export function updateRenderableTestimonial(renderableTestimonial) {
  const classifier_value_ids = renderableTestimonial.classifier_values?.map((c) => c.id)
  return axios.patch(`/app/api/renderable_testimonials/${renderableTestimonial.id}.json`, {
    renderable_testimonial: { ...renderableTestimonial, classifier_value_ids },
  })
}

// Fetches a matrixx chart instance from the App Api
export function getMatrixxChart(id) {
  return axios.get(`/app/api/matrixx_charts/${id}.json`)
}

// Fetches a matrixx chart instance from the public api
export function fetchPublicMatrixxChart(id) {
  return axios.get(`/api/matrixx_charts/${id}.json`)
}

export function updateMatrixxChart(matrixx_chart) {
  return axios.patch(`/app/api/matrixx_charts/${matrixx_chart.id}.json`, {
    matrixx_chart: matrixx_chart,
  })
}

export function createMatrixxChart(matrixx_chart) {
  return axios.post(`/app/api/matrixx_charts.json`, {
    matrixx_chart: matrixx_chart,
  })
}

export function deleteMatrixxChart(matrixx_chart) {
  return axios.delete(`/app/api/matrixx_charts/${matrixx_chart.id}.json`)
}

export function fetchPublicContentAsset(content_asset_id, options) {
  return axios.get(`/api/content_assets/${content_asset_id}.json`, { params: options })
}

export function patchRenderableTestimonial(rt) {
  return axios.patch(`/app/api/renderable_testimonials/${rt.id}.json`, {
    renderable_testimonial: rt,
  })
}

export function deleteRenderableTestimonial(id) {
  return axios.delete(`/app/api/renderable_testimonials/${id}.json`)
}

export function postImportTestimonials(testimonial_data) {
  return axios.post('/app/api/renderable_testimonials/import.json', {
    testimonials: testimonial_data,
  })
}

export function createRecipientUpload(postData) {
  return axios.post('/app/api/recipient_uploads.json', postData)
}

export function getSurveyRecipientUploads(survey_id) {
  return axios.get(`/app/api/surveys/${survey_id}/recipient_uploads.json`)
}

export function getSurveyResponses(params) {
  return axios.get(`/app/api/survey_responses.json`, {
    params: params,
  })
}
export function getSurveyResponse(id) {
  return axios.get(`/app/api/survey_responses/${id}.json`)
}

export function getSurveyResponsesByQuestion(surveyId, questionId, params) {
  return axios.get(`/app/api/surveys/${surveyId}/survey_responses.json?question_id=${questionId}`, {
    params: params,
  })
}

export function getRecipientUpload(recipient_upload_id) {
  return axios.get(`/app/api/recipient_uploads/${recipient_upload_id}.json`)
}

export function getRecipientUploadToken(survey_id) {
  return axios.get(`/app/api/tokens/recipients_upload/${survey_id}`)
}

export function getFilteredRecipients(url) {
  return axios.get(url)
}

export function postSendRequest(send_request) {
  return axios.post('/app/api/send_requests.json', {
    survey_id: send_request.survey_id,
    send_request: send_request,
  })
}

export function postBulkSendRequest(survey_id, emails) {
  return axios.post(`/app/api/send_requests/bulk_create.json`, {
    survey_id: survey_id,
    send_requests: emails,
  })
}

export function patchSendRequest(send_request) {
  return axios.patch(`/app/api/send_requests/${send_request.id}.json`, {
    send_request: send_request,
  })
}

export function postPreviewSendRequest(survey_id, preview_email_string, email) {
  return axios.post(`/app/api/send_requests/send_preview.json`, {
    survey_id: survey_id,
    preview_email_string: preview_email_string,
    subject: email.subject,
    email_text: email.email_text,
  })
}

export function getUsers() {
  return axios.get(`/app/api/users.json`)
}

// Fetches an individual user from within the logged in user's account
// Only editors can make this call
export function getUser(userId) {
  return axios.get(`/app/api/users/${userId}.json`)
}

// Updates an individual user from within the logged in user's account
// Only editors can make this call
export function updateAccountUser(user) {
  return axios.patch(`/app/api/account_users/${user.id}.json`, { user: user })
}

export function postUser(user) {
  return axios.post(`/app/api/users.json`, { user: user })
}

// Our API currently had two endpoints for fetching recipients. One is scoped to a survey
// and the other is not. This function hides that detail from the frontend by choosing the
// correct endpoint based on the presence of a surveyId.
export function getRecipients(surveyId, params) {
  if (surveyId) {
    return axios.get(`/app/api/surveys/${surveyId}/recipients.json`, { params: params })
  } else {
    return getAllRecipients(params)
  }
}

export function getAllRecipients(params) {
  return axios.get(`/app/api/recipients.json`, { params: params })
}

export function getSurveyReports(surveyId, params) {
  return axios.get(`/app/api/surveys/${surveyId}/reports.json`, { params: params })
}

export function getRecipientRoles() {
  return axios.get(`/api/recipient_roles.json`)
}

export function postRecipients(url, method, recipient) {
  return axios({
    url: url,
    method: method,
    data: {
      recipient: recipient,
    },
  })
}

export function deleteSubscription(recipient_id) {
  return axios.delete(`/api/email_subscriptions/${recipient_id}.json`)
}

export function postResponse(response) {
  return axios.post('/api/responses.json', response)
}

export function patchResponse(response) {
  const url = `/api/responses/${response.id}.json`
  return axios.patch(url, response)
}

export function getTags(taggable_id, taggable_type) {
  return axios.get(`/app/api/tags.json?taggable_id=${taggable_id}&taggable_type=${taggable_type}`)
}

export function updateTags(taggable_id, taggable_type, tags) {
  return axios.patch(`/app/api/tags/general_update.json`, {
    taggable_id,
    taggable_type,
    tags,
  })
}

/*
 * Recipient response
 */
//
// Fetches the survey + recipient + answers
// This is a legacy endpoint whose payload is a bit of a mess...
// The response data looks like this:
// {
//   survey: {
//     id: 1,
//     name: "Survey Name",
//     ...
//     questions: [],
//     account: {...},
//     recipients: [
//       {
//         id: 1,
//         first_name: "John",
//         responses: [
//           {
//             id: 1,
//             ansdwer_id: 1,
//             question_id: 1,
//           },
//           ...
//         ]
//       }
//     ]
//   },
// }
// We should probably refactor this to be more consistent with the rest of the API
// In the meantime, multiple screens depend on this response, so we'll need to keep
// it until we have time to refactor those screens.
export function getRecipientResponseData(recipientId) {
  return axios.get(`/app/api/recipients/${recipientId}.json`)
}

/*
 * Questions and Answers functions
 */

//
// Creates a Question
//
export function createQuestion(question) {
  return axios.post(`/app/api/questions.json`, {
    question: question,
  })
}

//
// Deletes a Question
//
export function deleteQuestion(question) {
  return axios.delete(`/app/api/questions/${question.id}.json`)
}

//
// Updates a Question
//
export function updateQuestion(question) {
  return axios.patch(`/app/api/questions/${question.id}.json`, {
    question: {
      the_question: question.the_question,
      classifier_id: question.classifier_id,
    },
  })
}

//
// Gets a question with all the answer stats associated with it
//
export function getQuestionWithStats(question_id) {
  return axios.get(`/app/api/questions/${question_id}/with_stats.json`)
}

//
// Gets a question.
//
export function justGetQuestion(question_id) {
  return axios.get(`/app/api/questions/${question_id}.json`)
}

//
// Creates an Answer (also used for creating matrixx columns)
//
export function createAnswer(answer) {
  return axios.post(`/app/api/answers.json`, answer)
}

//
// Deletes an Answer
//
export function deleteAnswer(answer) {
  return axios.delete(`/app/api/answers/${answer.id}.json`)
}

//
// Updates an Answer
//
export function updateAnswer(answer) {
  return axios.patch(`/app/api/answers/${answer.id}.json`, {
    answer: {
      the_answer: answer.the_answer,
    },
  })
}

//
// Create a classifier
//
export function createClassifier(classifier) {
  return axios.post(`/app/api/classifiers.json`, {
    classifier: classifier,
  })
}

export function getClassifiers() {
  return axios.get('/app/api/classifiers.json')
}

export function getClassifier(id) {
  return axios.get(`/app/api/classifiers/${id}.json`)
}

export function updateClassifier(classifier) {
  return axios.patch(`/app/api/classifiers/${classifier.id}.json`, { classifier })
}

export function deleteClassifier(classifier) {
  return axios.delete(`/app/api/classifiers/${classifier.id}.json`)
}

export function createClassifierValue(classifier_value) {
  return axios.post(
    `/app/api/classifiers/${classifier_value.classifier_id}/classifier_values.json`,
    {
      classifier_value: classifier_value,
    },
  )
}

export function createClassification(asset_identifier, classifier_value_id) {
  return axios.post('/app/api/classifications/', {
    classification: { asset_identifier, classifier_value_id },
  })
}

export function deleteClassification(asset_identifier, classifier_value_id) {
  // It's sketchy for a DELETE to have a body, but in this context it's very convenient since the relevant frontend
  // components tend not to have access to classification_id, so being a bit sketch for convenience
  return axios.delete('/app/api/classifications/', {
    data: { classification: { asset_identifier, classifier_value_id } },
  })
}

export function updateClassifierValue(classifier_value) {
  return axios.put(
    `/app/api/classifiers/${classifier_value.classifier_id}/classifier_values/${classifier_value.id}.json`,
    {
      classifier_value: classifier_value,
    },
  )
}

export function deleteClassifierValue(classifier_value) {
  return axios.delete(
    `/app/api/classifiers/${classifier_value.classifier_id}/classifier_values/${classifier_value.id}.json`,
  )
}

export function getIndustries() {
  return axios.get('/api/industries.json')
}

// Fetches all the aggregated questions for a survey along with their stat templates and
// other meta info to power the aggregated results screen
// this endpoint filters out Testimonial questions.
export function fetchAggregate(survey_id) {
  return axios.get(`/app/api/surveys/${survey_id}/aggregate.json`)
}

// Essentially the same as fetchAggregate but it does not filter out Testimonial questions.
export function fetchAggregatedQuestions(survey_id) {
  return axios.get(`/app/api/surveys/${survey_id}/aggregated_questions.json`)
}

export function getQuestions(surveyId) {
  return axios.get(`/app/api/v2/surveys/${surveyId}/questions.json`)
}

export function getQuestion(id, surveyId) {
  return axios.get(`/app/api/v2/surveys/${surveyId}/questions/${id}.json`)
}

// Fetches all the classifier_values associated with a recipient
export function fetchRecipientClassifierValues(recipient_id) {
  return axios.get(`/app/api/recipients/${recipient_id}/classifier_values.json`)
}

export function updateAccount(account) {
  return axios.put(`/app/api/v2/accounts/${account.id}.json`, { account })
}

export function attachAvatarToRecipient(recipient_id, avatar) {
  return axios.putForm(`/app/api/recipients/${recipient_id}/avatar.json`, {
    avatar,
  })
}

export function deleteAvatarFromRecipient(recipient_id) {
  return axios.delete(`/app/api/recipients/${recipient_id}/avatar.json`)
}

export function fetchSurveySummaries(surveyId) {
  return axios.get(`/app/api/v2/surveys/${surveyId}/survey_summaries.json`)
}

export function getSurveySummary(id) {
  return axios.get(`/app/api/v2/survey_summaries/${id}.json`)
}

export function newSurveySummary(survey_id) {
  return axios.get(`/app/api/v2/surveys/${survey_id}/survey_summaries/new.json`)
}

export function createSurveySummary(survey_id, survey_summary) {
  return axios.post(`/app/api/v2/surveys/${survey_id}/survey_summaries.json`, survey_summary)
}

// In the near future we probably want to be able to initialize testimonials based on something other than responses.
// We can accomplish that by adding a type param that supports values like 'response' to specify what type of entity
// the id is for
export function newRenderableTestimonial(params) {
  return axios.get(`/app/api/v2/testimonials/new.json`, {
    params: params,
  })
}

// // // // // // // // //
//
// BRAND FONT ENDPOINTS //
//
// // // // // // // // //
export function createBrandFontFamily(family, accountId) {
  const payload = {
    brand_font: family,
  }
  if (accountId) {
    payload.account_id = accountId
  }
  return axios.post('/app/api/brand_font.json', payload)
}

export function createBrandFontFace(face, accountId) {
  const formData = new FormData()
  formData.append('style', face.style)
  formData.append('weight', face.weight)
  formData.append('font_file', face.font_file)
  if (accountId) {
    formData.append('account_id', accountId)
  }
  return axios.post('/app/api/brand_font/font_faces.json', formData)
}

export function deleteBrandFontFace(faceId, accountId) {
  const payload = accountId ? { account_id: accountId } : {}
  return axios.delete(`/app/api/brand_font/font_faces/${faceId}.json`, { data: payload })
}

// SlackBot
export function deleteSlackbotConnection() {
  return axios.delete('/app/api/account/slackbot_connection.json')
}

// Gong methods...
export function getGongCall(id) {
  return axios.get(`/app/api/v2/gong_calls/${id}.json`)
}

export function getGongCalls(params) {
  return axios.get('/app/api/v2/gong_calls.json', { params: params })
}

export function createTranscriptAnalysisJob(params) {
  return axios.post('/app/api/jobs/transcript_analysis.json', { ...params })
}

export function createVideoLinkAsset(asset) {
  const classifier_value_ids = asset.classifier_values?.map((c) => c.id)
  return axios.post('/app/api/v2/video_link_assets.json', {
    video_link_asset: { ...asset, classifier_value_ids },
  })
}

export function updateVideoLinkAsset(asset) {
  const classifier_value_ids = asset.classifier_values?.map((c) => c.id)
  return axios.patch(`/app/api/v2/video_link_assets/${asset.id}.json`, {
    video_link_asset: { ...asset, classifier_value_ids },
  })
}

export function getVideoLinkAsset(assetId) {
  return axios.get(`/app/api/v2/video_link_assets/${assetId}.json`)
}

export function deleteVideoLinkAsset(asset) {
  return axios.delete(`/app/api/v2/video_link_assets/${asset.id}.json`)
}

// File Assets
export function getFileAsset(id, params) {
  return axios.get(`/app/api/v2/file_assets/${id}.json`, { ...params })
}

export function updateFileAsset(params) {
  const classifier_value_ids = params.classifier_values?.map((c) => c.id)
  return axios.patch(`/app/api/v2/file_assets/${params['id']}.json`, {
    ...params,
    classifier_value_ids,
  })
}

export function downloadFileAsset(id) {
  return axios.get(`/api/file_assets/${id}/download.json`)
}

export function deleteFileAsset(asset) {
  return axios.delete(`/app/api/v2/file_assets/${asset.id}.json`)
}
